import React from 'react'
import { navigate } from 'gatsby'
import API from '../utils/api'
import { getUserID, setUser, isLoggedIn } from '../utils/auth'
import AppContext from '../utils/context'
import Fade from 'react-reveal/Fade'
import Rotate from 'react-reveal/Rotate'
import ImageWatchHand from '../components/images/watch-hand'
import WatsonLogoSVG from '../components/svg/logo'
import Background from '../components/background'
import { setTeamData, getCurrentTeamData } from '../utils/team-data'
import { updateHuntData } from '../utils/hunt-data'
import ReactCodeInput from 'react-code-input'

export default class QRLoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      access_code: ``,
      name: ``,
      is_captain: false,
      loading: true,
      error: false,
      site_data: {},
    }
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.submitCode = this.submitCode.bind(this)
    this.codeRef = React.createRef()
  }

  componentDidMount() {
    const { location } = this.props
    if (location.search) {
      const query = new URLSearchParams(location.search)
      const access_code = query.get(`code`)
      this.submitCode(access_code)
    } else {
      if (isLoggedIn()) {
        navigate(`/`)
      } else {
        this.setState({ error: true })
      }
    }
  }

  handleUpdate(code) {
    this.setState({
      access_code: code,
    })
    if (code.length == 6) {
      this.setState({ loading: true })
      this.submitCode(code)
    }
  }

  async handleSubmit(event) {
    event.preventDefault()
    this.setState({ loading: true })
    this.submitCode()
  }

  submitCode(access_code) {
    this.setState({ loading: true })
    access_code = access_code.toLowerCase()
    API.get(`session/${access_code}/`)
      .then(res => {
        //console.log(res)
        //console.log(`Access Code matched! Setting the active user.`)
        const userId = getUserID()
        let is_captain = false
        if (res.data.team.members && res.data.team.members[userId]) {
          is_captain = res.data.team.members[userId].is_captain
        }
        if (res.data.team.permanent) {
          is_captain = true
        }
        setTeamData(res.data.team)
        setUser({
          guid: userId,
          name: name,
        })
        updateHuntData(res.data.team.hunt_id, res.data.team.start, res.data.team.direction).then(res => {
          const teamData = getCurrentTeamData()
          if (teamData.start_time) {
            // hunt has started already, skip to settings
            navigate(`/settings/`)
          } else {
            console.log(`navigating to get started`)
            navigate(`/`)
          }
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          error: true,
        })
      })
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ site_data, showAlert, setLoading }) => {
          this.showAlert = showAlert
          this.setLoading = setLoading
          const { error } = this.state
          return (
            <>
              {error && (
                <Fade>
                  <div className={`login-page`} style={{ minHeight: `100vh`, overflow: `hidden`, maxWidth: `100%` }}>
                    <Background alt="Watson Login Screen" filename="login.jpg" />

                    <div
                      style={{
                        //paddingTop: '100px',
                        margin: `auto`,
                        //maxWidth: '400px',
                        position: `relative`,
                        zIndex: `10`,
                      }}>
                      <div>
                        {site_data.greeting_text && (
                          <>
                            <Fade top big delay={1000}>
                              <div
                                className={`py-4 px-4 mb-5`}
                                style={{
                                  backgroundColor: `#F5F2ED`,
                                  boxShadow: `2px 2px 5px rgba(189, 186, 186, 0.5)`,
                                }}>
                                <div
                                  style={{
                                    maxWidth: `400px`,
                                    margin: `auto`,
                                  }}>
                                  <WatsonLogoSVG />
                                </div>
                              </div>
                            </Fade>
                            <div
                              style={{
                                maxWidth: `400px`,
                                margin: `auto`,
                              }}>
                              <Fade left big delay={1000}>
                                <h1 className={`text-center text-uppercase text-white h1 title mb-3 px-3`}>
                                  Ask your Team Captain for the six-digit code
                                </h1>
                              </Fade>
                              <Fade right big delay={1500}>
                                <h6
                                  className={`h6 lead text-center text-white mb-5 px-4`}
                                  style={{
                                    marginTop: `-10px`,
                                    lineHeight: `1.4`,
                                    fontStyle: `italic`,
                                  }}>
                                  It’s below the QR code.{` `}
                                </h6>
                              </Fade>

                              <form
                                className={`access-form-group text-center`}
                                method="post"
                                onSubmit={event => {
                                  this.handleSubmit(event)
                                }}>
                                <div className="form-group text-center py-3 d-inline-block px-3">
                                  <ReactCodeInput
                                    ref={this.codeRef}
                                    type="tel"
                                    pattern="[0-9]*"
                                    fields={6}
                                    onChange={this.handleUpdate}
                                    label={`Enter the 6 digit code`}
                                    novalidate
                                  />
                                </div>

                                <button type="submit" className="sr-only">
                                  Submit
                                </button>
                              </form>
                            </div>
                          </>
                        )}

                        <Fade bottom delay={2000}>
                          <div className="text-center text-white">
                            <small
                              className="copyright"
                              dangerouslySetInnerHTML={{ __html: site_data.copyright_text }}
                            />
                          </div>
                        </Fade>
                      </div>
                    </div>

                    <Rotate top right delay={2500}>
                      <div
                        style={{
                          position: `absolute`,
                          right: `0px`,
                          bottom: `60px`,
                        }}>
                        <ImageWatchHand />
                      </div>
                    </Rotate>
                  </div>
                </Fade>
              )}
            </>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
